import { PUBLIC_LOCAL_STORAGE_VERSION } from '@/consts'
import { decrypt, encrypt, logger } from '@/lib/client'
import { persistentAtom } from '@nanostores/persistent'

const EMPTY_ACCOUNT: { consent: boolean } = {
  consent: false,
}

const cookies = persistentAtom<{ consent: boolean }>(
  `dc_cookies/${PUBLIC_LOCAL_STORAGE_VERSION}`,
  EMPTY_ACCOUNT,
  {
    encode: (value) => {
      return btoa(encrypt(JSON.stringify(value)))
    },
    decode: (value) => {
      try {
        return JSON.parse(decrypt(atob(value)))
      } catch (error) {
        logger(
          'STORE COOKIES',
          JSON.stringify({
            name: error?.name,
            message: error?.message,
          }),
        )
        return value
      }
    },
  },
)

const store = { cookies }

const consent = () => {
  cookies.set({ consent: true })
}

const actions = { consent }

export { store, actions }
